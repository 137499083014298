import React from 'react';
import "./listaCobrosTours.css";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';

class ListaCobrosTours extends React.Component{
  constructor(props) {
    super(props);

    let fecha = new Date();

    this.state = {
      dataTable: [],
      filtro: '',
      loader: true,
      total:0
    };
    this.loadTableData();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  loadTableData = () => {
    this.setState({loader: true});
    axios.get('/api/CobrosController.php/CobrosTours',
    {
      params:{
        proveedor: this.state.filtro
      }
    })
    .then(result => {

      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          dataTable: result.data.data,
          total:0
        });

        this.unCheckAll(); 
      },1000);
    })
    .catch(console.log);
  }

  unCheckAll(){
    var cbarray = document.getElementsByName('cb');
    console.log(cbarray);
    for(var i = 0; i < cbarray.length; i++){
      cbarray[i].checked = false;
    }  
  }

  handleChange(event) {
    this.setState({filtro: event.target.value});
  }

  handleSubmit(event) {
    this.setState({loader: true});
    this.setState({
      currentPage : 0
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
    event.preventDefault();
  }

  cobrarModal = () => {
    if(this.state.total == 0){
      infoMessage(2, 'Mensaje del sistema', 'El total tiene que ser mayor a 0');
      return false;
    }
    confirmAlert({
      message: '¿Desea realizar el cobro['+this.state.total+']?',
      buttons: [
        {
          label: 'Continuar',
          onClick: () => this.realizarCobro()
        },
        {
          label: 'Cancelar'
        }
      ],
      overlayClassName: 'backGroundDialog'
    });
  }

  realizarCobro = () => {
    this.setState({loader: true});
    axios.post('/api/CobrosController.php/CobrosTours', this.state.dataTable)
    .then(result => {
      this.setState({loader: false});
      infoMessage(1, 'Mensaje del sistema', result.data.message);
      this.loadTableData();
    })
    .catch(console.log);
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="container-fluid min-vh-100"
      >
      	<div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
            	<div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-center">
                    <h3>Pagos Pendientes Tours</h3>
                  </div>
                  <div className="card-header d-flex justify-content-start">
                  	<form onSubmit={this.handleSubmit}>  
                      <div className="input-group input-group-sm mr-2" style={{width: "200px"}}>
                        <input type="text" className="form-control" placeholder="Buscar" 
                          value={this.state.filtro} onChange={this.handleChange}>
                        </input>
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-default">
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-info btn-block btn-sm" 
                        style={{width: "40px"}} 
                        onClick={() => this.loadTableData()}>
                        <i className="fa fa-sync"></i>
                      </button>
                    </div>
                    <div className="mr-2">
                      <Link to="/pagostours">
                        <button type="button" 
                          className="btn btn-danger btn-block btn-sm" 
                          style={{width: "150px"}} >
                          <i className="fa fa-money-bill-alt"></i> Pagar
                        </button>
                      </Link>
                    </div>
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-success btn-block btn-sm" 
                        style={{width: "150px"}} 
                        onClick={() => this.cobrarModal()}>
                        <i className="fa fa-money-bill-alt"></i> Cobrar
                      </button>
                    </div>
                    <div className="mr-2">
                      <b>$ {this.state.total}</b>
                    </div>
                  </div>
                  <div className="table-wrapper-scroll-y my-custom-scrollbar">
                    <table className="table table-bordered table-striped mb-0">
                      <thead>
                        <tr className="Table-Details">
                          <th>Check</th>
                          <th>Proveedor</th>
                          <th>Paquete</th>
                          <th>Fecha Reservacion</th>
                          <th>Cliente</th>
                          <th>Telefono</th>
                          <th>Ganancia</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dataTable.map((result, index) => {
                          return(
                            <tr>
                              <td><input type="checkbox" name="cb" onChange={(e) => {
                                result.checky = e.target.checked;
                                result.id_usuario = localStorage.getItem('NAid');
                                if(e.target.checked){
                                  this.setState({
                                    total: parseInt(this.state.total) + parseInt(result.ganancia_estimada)
                                  });
                                }else{
                                  this.setState({
                                    total: parseInt(this.state.total) - parseInt(result.ganancia_estimada)
                                  });
                                }
                              }}></input></td>
                              <td>{result.proveedor}</td>
                              <td>{result.paquete}</td>
                              <td>{result.fecha_reservacion}</td>
                              <td>{result.nombre}</td>
                              <td>{result.telefono}</td>
                              <td>{result.ganancia_estimada}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
      	</div>
      </LoadingOverlay>
    );
  }
}

export default ListaCobrosTours;