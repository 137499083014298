import React from 'react';
import "./polizasAutomovil.css";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import DatePicker from 'react-date-picker';

class PolizasAutomovil extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      poliza: '',
      detalles: '',
      cliente: '',
      telefono: '',
      correo: '',
      id_tipo_poliza: '',
      id_seguro_agente: '',
      fecha_emision:new Date(),
      ddTiposPolizas: [],
      ddSegurosAgentes: [],
    };

    this.tiposPolizasRef = React.createRef();
    this.segurosAgentesRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.setState({loader: true});
    axios.get('/api/CatalogosController.php/SegurosAgentes')
    .then(result => {
      this.setState({
        loader: false,
        ddSegurosAgentes: result.data.data,
        agenteSelected: result.data.data[0].value,
        id_seguro_agente: result.data.data[0].value
      });
      this.segurosAgentesRef.current.value = parseInt(result.data.data[0].value); 

      axios.get('/api/CatalogosController.php/TiposPolizas')
      .then(result => {
        this.setState({
          loader: false,
          ddTiposPolizas: result.data.data,
          polizaSelected: result.data.data[0].value,
          id_tipo_poliza: result.data.data[0].value
        });
        this.tiposPolizasRef.current.value = parseInt(result.data.data[0].value); 

        if(this.props.match.params.id !== undefined){
          this.setState({loader: true});
          axios.get('/api/PolizasAutomovilController.php/DetallesPolizaAutomovil',{
            params:{
              id:this.props.match.params.id,
            }
          })
          .then(result => {
            this.setState({
              loader: false,
              poliza: result.data.data[0].poliza,
              detalles: result.data.data[0].descripcion,
              cliente: result.data.data[0].cliente,
              telefono: result.data.data[0].telefono,
              correo: result.data.data[0].correo,
              fecha_emision:result.data.data[0].fecha_emision,
            });

            this.tiposPolizasRef.current.value = parseInt(result.data.data[0].id_tipo_poliza);
            this.segurosAgentesRef.current.value = parseInt(result.data.data[0].id_seguro_agente);
          })
          .catch(console.log);      
        }

      }).catch(console.log);

    }).catch(console.log);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  handleChangeDate(event) {
    this.setState({fecha: event});
  }

  guardarPoliza(){
    if(this.state.poliza == ''){
      infoMessage(2, 'Error de captura','Capture la poliza para poder continuar.');
      return false;
    }
    if(this.state.cliente == ''){
      infoMessage(2, 'Error de captura','Capture el cliente para poder continuar.');
      return false;
    }
    if(this.state.telefono == ''){
      infoMessage(2, 'Error de captura','Capture el telefono para poder continuar.');
      return false;
    }
    if(this.state.fecha_emision == ''){
      infoMessage(2, 'Error de captura','Capture la fecha de emision para poder continuar.');
      return false;
    }
    if(this.state.id_seguro_agente == ''){
      infoMessage(2, 'Error de captura','Capture el agente para poder continuar.');
      return false;
    }
    if(this.state.id_tipo_poliza == ''){
      infoMessage(2, 'Error de captura','Capture el tipo de poliza para poder continuar.');
      return false;
    }

    this.setState({loader: true});
    if(this.props.match.params.id !== undefined){
      axios.put('/api/PolizasAutomovilController.php/PolizasAutomovil',{
        id:this.props.match.params.id,
        poliza: this.state.poliza,
        cliente: this.state.cliente, 
        telefono: this.state.telefono, 
        correo: this.state.correo, 
        fecha_emision: this.state.fecha_emision,
        id_seguro_agente: this.state.id_seguro_agente, 
        id_tipo_poliza: this.state.id_tipo_poliza, 
        detalles: this.state.detalles,
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }else{
      axios.post('/api/PolizasAutomovilController.php/PolizasAutomovil',
      {
        poliza: this.state.poliza,
        cliente: this.state.cliente, 
        telefono: this.state.telefono, 
        correo: this.state.correo, 
        fecha_emision: this.state.fecha_emision,
        id_seguro_agente: this.state.id_seguro_agente, 
        id_tipo_poliza: this.state.id_tipo_poliza, 
        detalles: this.state.detalles,
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div className="form-row">
                      <div className="form-group required col-md-4">
                        <label className="control-label">Poliza</label>
                        <input type="text" className="form-control" name="poliza" 
                        maxLength="60" value={this.state.poliza} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Cliente</label>
                        <input type="text" className="form-control" name="cliente" 
                        maxLength="60" value={this.state.cliente} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Telefono</label>
                        <input type="number" className="form-control" name="telefono" 
                        maxLength="10" value={this.state.telefono} onChange={this.handleInputChange} ></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-3">
                        <label className="control-label">Correo</label>
                        <input type="text" className="form-control" name="correo" 
                        maxLength="60" value={this.state.correo} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Fecha De Emision</label>
                        <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                          value={this.state.fecha_emision}/>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Tipo De Poliza</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_tipo_poliza} name="id_tipo_poliza" ref={this.tiposPolizasRef}>
                          {this.state.ddTiposPolizas.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Agente</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_seguro_agente} name="id_seguro_agente" ref={this.segurosAgentesRef}>
                          {this.state.ddSegurosAgentes.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="inputAddress">Detalles</label>
                      <textarea type="text" className="form-control" name="detalles"
                        value={this.state.detalles} onChange={this.handleInputChange} ></textarea>
                    </div>
                    <div className="form-row justify-content-md-center">
                      <Link to="/listapolizasautomovil">
                        <button type="button" 
                          className="btn btn-dark btn-block mr-2" 
                          style={{width: "150px"}} >
                          <i className="fa fa-arrow-circle-left"></i> Volver
                        </button>
                      </Link>
                      <button type="button" 
                        className="btn btn-primary btn-block" 
                        style={{width: "150px"}}
                        onClick={() => this.guardarPoliza()} >
                        <i className="fa fa-save"></i> Guardar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default PolizasAutomovil;