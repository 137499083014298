import React from 'react';
import "./listaIngresosGastos.css";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

class ListaIngresosGastos extends React.Component{
  constructor(props) {
    super(props);

    let fecha = new Date();

    this.state = {
      dataTable: [],
      filtro: '',
      loader: true,
      currentPage: 0,
      totalPages: 1,
      limitPage: 10,
      filtroFechas:[ 
        new Date(fecha.setDate(fecha.getDate()-7)),
        new Date(fecha.setDate(fecha.getDate()+14))
      ],
      selectedRow: 0,
    };
    this.loadTableData();
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  loadTableData = () => {
    this.setState({loader: true});
    axios.get('/api/PagosController.php/Pagos',
    {
      params:{
        q:this.state.filtro,
        currentPage:this.state.currentPage,
        totalPages:this.state.totalPages,
        limitPage:this.state.limitPage,
        fechaInicial:this.state.filtroFechas[0].toISOString().split('T')[0],
        fechaFinal:this.state.filtroFechas[1].toISOString().split('T')[0],
      }
    })
    .then(result => {
      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          dataTable: result.data.data.result,
          totalPages: result.data.data.totalPages
        });
      },1000);
    })
    .catch(console.log);
  }

  handleChange(event) {
    this.setState({filtro: event.target.value});
  }

  handleChangeDate(event) {
    this.setState({filtroFechas: event});
  }

  handleSubmit(event) {
    this.setState({loader: true});
    this.setState({
      currentPage : 0
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
    event.preventDefault();
  }

  initialPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage : 0
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  prevPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage :Math.max(0, (this.state.currentPage - 1))
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  nextPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage : Math.min(this.state.totalPages, (this.state.currentPage + 1))
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  lastPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage : this.state.totalPages -1
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  eliminarPago(id){
    confirmAlert({
      message: '¿Desea elimnar el pago?',
      buttons: [
        {
          label: 'Continuar',
          onClick: () => {
            this.setState({loader: true});
            axios.delete('/api/PagosController.php/Pagos?id='+id)
            .then(result => {
              this.setState({loader: false});
              infoMessage(1, 'Mensaje del sistema', result.data.message);
              this.loadTableData();
            })
            .catch(console.log);
          }
        },
        {
          label: 'Cancelar'
        }
      ],
      overlayClassName: 'backGroundDialog'
    });
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="container-fluid min-vh-100"
      >
      	<div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
            	<div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-center">
                    <h3>Ingresos Y Gastos</h3>
                  </div>
                  <div className="card-header d-flex justify-content-start">
                  	<form onSubmit={this.handleSubmit}>  
                      <div className="input-group input-group-sm mr-2" style={{width: "200px"}}>
                        <input type="text" className="form-control" placeholder="Buscar" 
                          value={this.state.filtro} onChange={this.handleChange}>
                        </input>
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-default">
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="mr-2">
                      <DateRangePicker className="mr-2" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                        value={this.state.filtroFechas}/>
                    </div>
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-info btn-block btn-sm" 
                        style={{width: "40px"}} 
                        onClick={() => this.loadTableData()}>
                        <i className="fa fa-sync"></i>
                      </button>
                    </div>
                    <div className="mr-2">
                      <Link to="/pagos/3/1">
                        <button type="button" 
                          className="btn btn-success btn-block btn-sm" 
                          style={{width: "150px"}} >
                          <i className="fa fa-plus-circle"></i> Agregar
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="table-wrapper-scroll-y my-custom-scrollbar">
                    <table className="table table-bordered table-striped mb-0">
                      <thead>
                        <tr className="Table-Details">
                          <th>Dependencia</th>
                          <th>Nombre</th>
                          <th>Forma Pago</th>
                          <th>Cantidad</th>
                          <th>Fecha</th>
                          <th>Descripcion</th>
                          <th>Tipo</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dataTable.map((result) => {
                          return(
                            <tr>
                              <td>{result.descripcion_reservacion}</td>
                              <td>{result.nombre}</td>
                              <td>{result.forma_pago}</td>
                              <td>{result.cantidad}</td>
                              <td>{result.fecha_registro}</td>
                              <td>{result.descripcion}</td>
                              <td><span className={result.estado_tipo}>{result.tipo}</span></td>
                              <td> 
                                <div className="d-flex justify-content-center">
                                  <div className="input-group-append">
                                    <button type="submit" className="btn btn-danger" 
                                      onClick={() => this.eliminarPago(result.id)}>
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer clearfix Table-Details">
                    <div className="pagination pagination-sm m-0 float-left">
                      <b>Pagina {this.state.currentPage + 1} de {this.state.totalPages}</b>
                    </div>
                    <div className="pagination pagination-sm m-0 float-right">
                      <li className="page-item">
                        <button disabled={this.state.currentPage == 0} 
                          type="button" className="btn btn-link" onClick={() => this.initialPage()}>
                          <i className="fa fa-angle-double-left"></i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button disabled={this.state.currentPage == 0} 
                          type="button" className="btn btn-link" onClick={() => this.prevPage()}>
                          <i className="fa fa-angle-left"></i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button disabled={this.state.currentPage + 1 == this.state.totalPages} 
                          type="button" className="btn btn-link" onClick={() => this.nextPage()}>
                          <i className="fa fa-angle-right"></i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button disabled={this.state.currentPage + 1 == this.state.totalPages} 
                          type="button" className="btn btn-link" onClick={() => this.lastPage()}>
                          <i className="fa fa-angle-double-right"></i>
                        </button>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      	</div>
      </LoadingOverlay>
    );
  }
}

export default ListaIngresosGastos;