import React from 'react';
import './App.css';
import Header from './Header';
import Dashboard from './Dashboard';
import Footer from './Footer';
import { BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import ListaProveedoresYates from "./pages/listaProveedoresYates/ListaProveedoresYates";
import ProveedorYate from "./pages/proveedorYate/ProveedorYate";
import ListaYates from "./pages/listaYates/ListaYates";
import Yate from "./pages/yate/Yate";
import ListaReservacionesYates from "./pages/listaReservacionesYates/ListaReservacionesYates";
import ReservacionYate from "./pages/reservacionYate/ReservacionYate";
import PagosYates from "./pages/pagosYates/PagosYates";
import ListaProveedoresTours from "./pages/listaProveedoresTours/ListaProveedoresTours";
import ProveedorTour from "./pages/proveedorTour/ProveedorTour";
import ListaTours from "./pages/listaTours/ListaTours";
import Tour from "./pages/tour/Tour";
import ListaReservacionesTours from "./pages/listaReservacionesTours/ListaReservacionesTours";
import ReservacionTour from "./pages/reservacionTour/ReservacionTour";
import ListaIngresosGastos from "./pages/listaIngresosGastos/ListaIngresosGastos";
import GastosGenerales from "./pages/gastosGenerales/GastosGenerales";
import ListaCobrosTours from "./pages/listaCobrosTours/ListaCobrosTours";
import FinanzasTours from "./pages/finanzasTours/FinanzasTours";
import PagosTours from "./pages/pagosTours/PagosTours";
import FinanzasYates from "./pages/finanzasYates/FinanzasYates";
import ListaAgentesSeguros from "./pages/listaAgentesSeguros/ListaAgentesSeguros";
import AgentesSeguros from "./pages/agentesSeguros/AgentesSeguros";
import ListaPolizasAutomovil from "./pages/listaPolizasAutomovil/ListaPolizasAutomovil";
import PolizasAutomovil from "./pages/polizasAutomovil/PolizasAutomovil";
import ListaPagosPolizasAutomovil from "./pages/listaPagosPolizasAutomovil/ListaPagosPolizasAutomovil";
import LlamadaPolizasAutomovil from "./pages/llamadaPolizasAutomovil/LlamadaPolizasAutomovil";
import CatalogoCasas from "./pages/catalogoCasas/CatalogoCasas";

class App extends React.Component {

  logout = () => {
    confirmAlert({
      message: '¿Desea salir del sistema?',
      buttons: [
        {
          label: 'Continuar',
          onClick: () => {
        		localStorage.removeItem('NAid');
        		localStorage.removeItem('NAuser');
        		localStorage.removeItem('NAmail');
        		window.location.reload();
          }
        },
        {
          label: 'Cancelar'
        }
      ],
      overlayClassName: 'backGroundDialog'
    });
  }	

  render(){
	  return (
	    <div class="wrapper">
	      <Header/>
	      <Router>
		      <div>
		        <aside className="main-sidebar sidebar-dark-primary elevation-4">
		          <a href="index3.html" className="brand-link" style={{height: '60px'}}>  
		            <img src="../dist/img/logo_white_large.png" alt="AdminLTE Logo" className="brand-image" />
		          </a>
		          <div className="sidebar">
		            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
		              <div className="image">
		                <img src="../dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
		              </div>
		              <div className="info">
		                <a href="#" className="d-block">Juan Del Diablo</a>
		              </div>
		            </div>
		            <nav className="mt-2">
		              <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
		                <li className="nav-item has-treeview">
		                  <a href="#" className="nav-link">
		                    ⚓
		                    <p>
		                      Yates
		                      <i className="right fas fa-angle-left" />
		                    </p>
		                  </a>
		                  <ul className="nav nav-treeview ml-2">
		                    <li className="nav-item">
		                      <Link to="/listaproveedoresyates" className="nav-link">
				                    👨‍
				                    <p>
				                      Proveedores
				                    </p>
				                  </Link>
		                    </li>
		                    <li className="nav-item">
		                      <Link to="/listayates" className="nav-link">
				                    🚢
				                    <p>
				                      Yates
				                    </p>
				                  </Link>
		                    </li>
		                    <li className="nav-item">
		                      <Link to="/listareservacionesyates" className="nav-link">
				                    🚩
				                    <p>
				                      Reservaciones
				                    </p>
				                  </Link>
		                    </li>
		                    <li className="nav-item">
		                      <Link to="/finanzasyates" className="nav-link">
				                    💹
				                    <p>
				                      Finanzas
				                    </p>
				                  </Link>
		                    </li>
		                  </ul>
		                </li>
		                <li className="nav-item has-treeview">
		                  <a href="#" className="nav-link">
		                    🏝️
		                    <p>
		                      Tours
		                      <i className="right fas fa-angle-left" />
		                    </p>
		                  </a>
		                  <ul className="nav nav-treeview ml-2">
		                    <li className="nav-item">
		                      <Link to="/listaproveedorestours" className="nav-link">
				                    👨‍
				                    <p>
				                      Proveedores
				                    </p>
				                  </Link>
		                    </li>
		                    <li className="nav-item">
		                      <Link to="/listatours" className="nav-link">
				                    🏖️
				                    <p>
				                      Tours
				                    </p>
				                  </Link>
		                    </li>
		                    <li className="nav-item">
		                      <Link to="/listareservacionestours" className="nav-link">
				                    🚩
				                    <p>
				                      Reservaciones
				                    </p>
				                  </Link>
		                    </li>
		                    <li className="nav-item">
		                      <Link to="/listacobrostours" className="nav-link">
				                    💰
				                    <p>
				                      Pagos
				                    </p>
				                  </Link>
		                    </li>
		                    <li className="nav-item">
		                      <Link to="/finanzastours" className="nav-link">
				                    💹
				                    <p>
				                      Finanzas
				                    </p>
				                  </Link>
		                    </li>
		                  </ul>
		                </li>
		                <li className="nav-item has-treeview">
		                  <a href="#" className="nav-link">
		                    🏘️
		                    <p>
		                      Hospedaje
		                      <i className="right fas fa-angle-left" />
		                    </p>
		                  </a>
		                  <ul className="nav nav-treeview ml-2">
		                    <li className="nav-item">
		                      <Link to="/catalogocasas" className="nav-link">
				                    🏡
				                    <p>
				                      Catalogo De Casas
				                    </p>
				                  </Link>
		                    </li>
		                  </ul>
		                </li>
	                  <a className="nav-link" onClick={() => this.logout()}>
	                    🔒
	                    <p className="ml-1">
	                      Salir
	                    </p>
	                  </a>
		              </ul>
		            </nav>
		          </div>
		        </aside>
		      </div>
  			  <div className="content-wrapper">
		      <Switch>
		      	<Route path="/listaproveedoresyates" component={ListaProveedoresYates} exact />
		      	<Route path="/nuevoproveedoryate" component={ProveedorYate} exact />
		      	<Route path="/editarproveedoryate/:id" component={ProveedorYate} exact />
		      	<Route path="/listayates" component={ListaYates} exact />
		      	<Route path="/nuevoyate" component={Yate} exact />
		      	<Route path="/editaryate/:id" component={Yate} exact />
		      	<Route path="/listareservacionesyates" component={ListaReservacionesYates} exact />
		      	<Route path="/nuevareservacionyate" component={ReservacionYate} exact />
		      	<Route path="/pagosyates/:id_reservacion" component={PagosYates} exact />
		      	<Route path="/listaproveedorestours" component={ListaProveedoresTours} exact />
		      	<Route path="/nuevoproveedortour" component={ProveedorTour} exact />
		      	<Route path="/editarproveedortour/:id" component={ProveedorTour} exact />
		      	<Route path="/listatours" component={ListaTours} exact />
		      	<Route path="/nuevotour" component={Tour} exact />
		      	<Route path="/editartour/:id" component={Tour} exact />
		      	<Route path="/listareservacionestours" component={ListaReservacionesTours} exact />
		      	<Route path="/nuevareservaciontour" component={ReservacionTour} exact />
		      	<Route path="/editarreservaciontour/:id" component={ReservacionTour} exact />
		      	<Route path="/listaingresosgastos" component={ListaIngresosGastos} exact />
		      	<Route path="/gastosgenerales" component={GastosGenerales} exact />
		      	<Route path="/listacobrostours" component={ListaCobrosTours} exact />
		      	<Route path="/finanzastours" component={FinanzasTours} exact />
		      	<Route path="/pagostours" component={PagosTours} exact />
		      	<Route path="/finanzasyates" component={FinanzasYates} exact />
		      	<Route path="/listaagentesseguros" component={ListaAgentesSeguros} exact />
		      	<Route path="/nuevoagenteseguro" component={AgentesSeguros} exact />
		      	<Route path="/editaragenteseguro/:id" component={AgentesSeguros} exact />
		      	<Route path="/listapolizasautomovil" component={ListaPolizasAutomovil} exact />
		      	<Route path="/nuevapolizaautomovil" component={PolizasAutomovil} exact />
		      	<Route path="/editarpolizaautomovil/:id" component={PolizasAutomovil} exact />
		      	<Route path="/listapagospolizasautomovil" component={ListaPagosPolizasAutomovil} exact />
		      	<Route path="/llamadapolizaautomovil/:id" component={LlamadaPolizasAutomovil} exact />
		      	<Route path="/catalogocasas" component={CatalogoCasas} exact />
		      	<Route path="/" exact />
		      </Switch>
        	  </div>
		  </Router>
	      <Footer/>
	    </div>
	  );
	}
}

export default App;
