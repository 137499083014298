import React, { Component } from 'react';
import './login.css';
import axios from 'axios';
import infoMessage from '../../components/infoMessage/InfoMessage';

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      usuario: '',
      password: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeEnter = this.handleInputChangeEnter.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  handleInputChangeEnter(event) {
    if (event.key === 'Enter') {
      this.ingresaUsuario();
    }
  }

  ingresaUsuario(){
    if(this.state.usuario == ''){
      infoMessage(2, 'Error de captura','Capture su usuario para poder continuar.');
      return false;
    }
    if(this.state.password == ''){
      infoMessage(2, 'Error de captura','Capture su contraseña para poder continuar.');
      return false;
    }

    this.setState({loader: true});
    axios.get('/api/LoginController.php/Login',{
      params: {
        usuario: this.state.usuario, 
        contrasena: this.state.password, 
      }
    })
    .then(result => {
      this.setState({loader: false});
      let tipo = 1;
      if(result.data.success){
        localStorage.setItem('NAid', result.data.data[0].['id_usuario']);
        localStorage.setItem('NAuser', result.data.data[0].['nombre']);
        localStorage.setItem('NAmail', result.data.data[0].['correo']);
        window.location.reload();
      }else{
        infoMessage(2, 'Mensaje del sistema',result.data.message);
        return false;
      }
    })
    .catch(console.log);
  }

  render() {
    return (
      <div class="hold-transition login-page">
        <div class="login-box">
          <div class="card card-outline card-primary">
            <div class="card-header text-center">
              <span class="h1">Admin<b>NT</b></span>
            </div>
            <div class="card-body">
              <div class="input-group mb-3">
                <input type="email" class="form-control" placeholder="Usuario" name="usuario" 
                  maxLength="30" value={this.state.usuario} onChange={this.handleInputChange}
                  onKeyDown={this.handleInputChangeEnter}></input>
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-user"></span>
                  </div>
                </div>
              </div>
              <div class="input-group mb-3">
                <input type="password" class="form-control" placeholder="Password" name="password" 
                  maxLength="30" value={this.state.password} onChange={this.handleInputChange}
                  onKeyDown={this.handleInputChangeEnter}></input>
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4"></div>
                <div class="col-4">
                  <button class="btn btn-primary btn-block" 
                    onClick={() => this.ingresaUsuario()}>Ingresar</button>
                </div>
                <div class="col-4"></div>
              </div>
              <p class="mb-1 pt-3">
                <a onClick={() => window.alert("¿Como se te olvido tu pinche contraseña? Bueno que pendejo eres de verdad")}>
                  ¿Olvidaste tu contraseña? Da click aqui!
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;