import React from 'react';
import "./listaTours.css";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link, useHistory} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';

class ListaTours extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      dataTable: [],
      dataTableTour: [],
      filtro: '',
      loader: true,
      currentPage: 0,
      totalPages: 1,
      limitPage: 10,
      selectedRow: 0,
      newTour: false,
      nombre: '',
      descripcion: '',
      precio_proveedor: 0,
      precio_publico: 0,
      idPaqueteTour:0,
    };
    this.loadTableData();
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  loadTableData = () => {
    this.setState({loader: true});
    axios.get('/api/ToursController.php/Tours',
    {
      params:{
        q:this.state.filtro,
        currentPage:this.state.currentPage,
        totalPages:this.state.totalPages,
        limitPage:this.state.limitPage,
      }
    })
    .then(result => {
      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          dataTable: result.data.data.result,
          totalPages: result.data.data.totalPages
        });
      },1000);
    })
    .catch(console.log);
  }

  loadSecondTableData = () => {
    this.setState({loader: true});
    axios.get('/api/ToursController.php/ToursList',
    {
      params:{
        id:this.state.selectedRow
      }
    })
    .then(result => {
      setTimeout(() => {
        this.setState({
          loader: false,
          dataTableTour: result.data.data
        });
      },1000);
    })
    .catch(console.log);
  }

  handleChange(event) {
    this.setState({filtro: event.target.value});
  }

  handleSubmit(event) {
    this.setState({loader: true});
    this.setState({
      currentPage : 0
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
    event.preventDefault();
  }

  deleteModal = (identificador, detalle) => {
    confirmAlert({
      message: 'Desea eliminar el registro['+detalle+'].',
      buttons: [
        {
          label: 'Continuar',
          onClick: () => this.deleteTour(identificador)
        },
        {
          label: 'Cancelar'
        }
      ],
      overlayClassName: 'backGroundDialog'
    });
  }

  deletePaqueteModal = (identificador, detalle) => {
    confirmAlert({
      message: 'Desea eliminar el registro['+detalle+'].',
      buttons: [
        {
          label: 'Continuar',
          onClick: () => this.deletePaqueteTour(identificador)
        },
        {
          label: 'Cancelar'
        }
      ],
      overlayClassName: 'backGroundDialog'
    });
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  deleteTour = (identificador) => {
    this.setState({loader: true});
    axios.delete('/api/ToursController.php/Tours?id='+identificador)
    .then(result => {
      this.setState({loader: false});
      infoMessage(1, 'Mensaje del sistema', result.data.message);
      this.loadTableData();
    })
    .catch(console.log);
  }

  deletePaqueteTour = (identificador) => {
    this.setState({loader: true});
    axios.delete('/api/ToursController.php/ToursList?id='+identificador)
    .then(result => {
      this.setState({loader: false});
      infoMessage(1, 'Mensaje del sistema', result.data.message);
      this.loadSecondTableData();
    })
    .catch(console.log);
  }

  initialPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage : 0
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  prevPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage :Math.max(0, (this.state.currentPage - 1))
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  nextPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage : Math.min(this.state.totalPages, (this.state.currentPage + 1))
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  lastPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage : this.state.totalPages -1
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  selectRow = (id) => {
    this.setState({
      loader: true,
      selectedRow: id,
      newTour:false,
    });
    setTimeout(() => {
      this.loadSecondTableData();
    }, 1000);
  }

  formularioTour = (val, data) => {
    if(Object.keys(data).length === 0){
      this.setState({
        newTour: val,
        nombre: '',
        descripcion: '',
        precio_proveedor: 0,
        precio_publico: 0,
        idPaqueteTour:0,
      });
    }else{
      this.setState({
        newTour: val,
        nombre: data.nombre,
        descripcion: data.descripcion,
        precio_proveedor: data.precio_proveedor,
        precio_publico: data.precio_publico,
        idPaqueteTour: data.id,
      });
    }
  }

  guardarTour = () => {
    if(this.state.nombre == ''){
      infoMessage(2, 'Error de captura','Capture el nombre para poder continuar.');
      return false;
    }
    if(this.state.descripcion == ''){
      infoMessage(2, 'Error de captura','Capture la descripcion para poder continuar.');
      return false;
    }
    if(this.state.precio_proveedor == ''){
      infoMessage(2, 'Error de captura','Capture el precio proveedor para poder continuar.');
      return false;
    }
    if(this.state.precio_publico == ''){
      infoMessage(2, 'Error de captura','Capture el precio publico para poder continuar.');
      return false;
    }

    this.setState({loader: true});
    if(this.state.idPaqueteTour != 0){
      axios.put('/api/ToursController.php/ToursList',{
        id:this.state.idPaqueteTour,
        nombre:this.state.nombre,
        id_tour: this.state.selectedRow,
        descripcion: this.state.descripcion,
        precio_publico: this.state.precio_publico,
        precio_proveedor: this.state.precio_proveedor,
      })
      .then(result => {
        this.setState({loader: false});
        if(result.data.success){
          this.setState({
            nombre:'',
            descripcion:'',
            precio_proveedor:'',
            precio_publico:'',
            newTour:false
          });
          this.loadSecondTableData();
        }else{
          infoMessage(2, 'Mensaje del sistema', result.data.message);
        }
      })
      .catch(console.log);
    }else{
      axios.post('/api/ToursController.php/ToursList',
      {
        nombre:this.state.nombre,
        id_tour: this.state.selectedRow,
        descripcion: this.state.descripcion,
        precio_publico: this.state.precio_publico,
        precio_proveedor: this.state.precio_proveedor,
      })
      .then(result => {
        this.setState({loader: false});
        if(result.data.success){
          this.setState({
            nombre:'',
            descripcion:'',
            precio_proveedor:'',
            precio_publico:'',
            newTour:false
          });
          this.loadSecondTableData();
        }else{
          infoMessage(2, 'Mensaje del sistema', result.data.message);
        }
      })
      .catch(console.log);
    }
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="container-fluid min-vh-100"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-center">
                    <h3>Tours</h3>
                  </div>
                  <div className="card-header d-flex justify-content-start">
                    <form onSubmit={this.handleSubmit}>  
                      <div className="input-group input-group-sm mr-2" style={{width: "200px"}}>
                        <input type="text" className="form-control" placeholder="Buscar" 
                          value={this.state.filtro} onChange={this.handleChange}>
                        </input>
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-default">
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-info btn-block btn-sm" 
                        style={{width: "40px"}} 
                        onClick={() => this.loadTableData()}>
                        <i className="fa fa-sync"></i>
                      </button>
                    </div>
                    <div className="mr-2">
                      <Link to="/nuevotour">
                        <button type="button" 
                          className="btn btn-success btn-block btn-sm" 
                          style={{width: "150px"}} >
                          <i className="fa fa-plus-circle"></i> Agregar
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="table-wrapper-scroll-y my-custom-scrollbar">
                    <table className="table table-bordered table-striped mb-0">
                      <thead>
                        <tr className="Table-Details">
                          <th>Tour</th>
                          <th>Proveedor</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dataTable.map((result) => {
                          return(
                            <tr className={this.state.selectedRow == result.id ? 'Selected-Table-Row': null}
                              onDoubleClick={() => this.selectRow(result.id)}>
                              <td>{result.nombre}</td>
                              <td>{result.proveedor}</td>
                              <td> 
                                <div class="d-flex justify-content-center">
                                  <div className="input-group-append mr-2">
                                    <Link to={"/editartour/"+result.id}>
                                      <button type="submit" className="btn btn-primary btn-sm">
                                        <i className="fas fa-pencil-alt"></i>
                                      </button>
                                    </Link>
                                  </div>
                                  <div className="input-group-append mr-2">
                                    <button type="button" 
                                      className="btn btn-success btn-sm"
                                      onClick={() => this.selectRow(result.id)}>
                                      <i className="fa fa-umbrella-beach"></i>
                                    </button>
                                  </div>
                                  <div className="input-group-append">
                                    <button type="submit" className="btn btn-danger btn-sm" 
                                      onClick={() => this.deleteModal(result.id, result.nombre)}>
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer clearfix Table-Details">
                    <div className="pagination pagination-sm m-0 float-left">
                      <b>Pagina {this.state.currentPage + 1} de {this.state.totalPages}</b>
                    </div>
                    <div className="pagination pagination-sm m-0 float-right">
                      <li className="page-item">
                        <button disabled={this.state.currentPage == 0} 
                          type="button" class="btn btn-link" onClick={() => this.initialPage()}>
                          <i className="fa fa-angle-double-left"></i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button disabled={this.state.currentPage == 0} 
                          type="button" class="btn btn-link" onClick={() => this.prevPage()}>
                          <i className="fa fa-angle-left"></i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button disabled={this.state.currentPage + 1 == this.state.totalPages} 
                          type="button" class="btn btn-link" onClick={() => this.nextPage()}>
                          <i className="fa fa-angle-right"></i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button disabled={this.state.currentPage + 1 == this.state.totalPages} 
                          type="button" class="btn btn-link" onClick={() => this.lastPage()}>
                          <i className="fa fa-angle-double-right"></i>
                        </button>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        { 
          this.state.selectedRow != 0 && !this.state.newTour ?
          <div className="wrapper" style={{paddingTop:"10px"}}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header d-flex justify-content-center">
                      <h3>Paquetes Del Tour</h3>
                    </div>
                    <div className="card-header d-flex justify-content-start">
                      <div className="mr-2">
                        <button type="button" 
                          className="btn btn-info btn-block btn-sm" 
                          style={{width: "40px"}} 
                          onClick={() => this.loadSecondTableData()}>
                          <i className="fa fa-sync"></i>
                        </button>
                      </div>
                      <div className="mr-2">
                        <button type="button" 
                          className="btn btn-success btn-block btn-sm" 
                          style={{width: "150px"}} 
                          onClick={() => this.formularioTour(true, {})}>
                          <i className="fa fa-plus-circle"></i> Agregar
                        </button>
                      </div>
                    </div>
                    <div className="table-wrapper-scroll-y my-custom-scrollbar">
                      <table className="table table-bordered table-striped mb-0">
                        <thead>
                          <tr className="Table-Details">
                            <th>Paquete</th>
                            <th>Descripcion</th>
                            <th>Precio Proveedor</th>
                            <th>Precio Publico</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.dataTableTour.map((result) => {
                            return(
                              <tr>
                                <td>{result.nombre}</td>
                                <td>{result.descripcion}</td>
                                <td>{result.precio_proveedor}</td>
                                <td>{result.precio_publico}</td>
                                <td> 
                                  <div class="d-flex justify-content-center">
                                    <div className="input-group-append mr-2">
                                      <button type="submit" className="btn btn-primary btn-sm"
                                        onClick={() => this.formularioTour(true, result)}>
                                        <i className="fas fa-pencil-alt"></i>
                                      </button>
                                    </div>
                                    <div className="input-group-append">
                                      <button type="submit" className="btn btn-danger btn-sm" 
                                        onClick={() => this.deletePaqueteModal(result.id, result.nombre)}>
                                        <i className="fas fa-trash-alt"></i>
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <p></p>
        }
        { 
          this.state.selectedRow != 0 && this.state.newTour ?
          <div className="wrapper" style={{paddingTop:"10px"}}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card" style={{padding:"10px"}}>
                    <form>
                      <div className="form-row">
                        <div className="form-group required col-md-6">
                          <label className="control-label">Nombre</label>
                          <input type="text" className="form-control" placeholder="Nombre" name="nombre" 
                          maxLength="60" value={this.state.nombre} onChange={this.handleInputChange} ></input>
                        </div>
                        <div className="form-group required col-md-6">
                          <label className="control-label">Descripcion</label>
                          <input type="text" className="form-control" placeholder="Descripcion" name="descripcion" 
                          maxLength="60" value={this.state.descripcion} onChange={this.handleInputChange} ></input>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group required col-md-6">
                          <label className="control-label">Precio Proveedor</label>
                          <div className="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">$</span>
                            </div>
                            <input type="number" className="form-control" placeholder="Precio Proveedor" name="precio_proveedor"
                            maxLength="7" value={this.state.precio_proveedor} onChange={this.handleInputChange} ></input>
                            <div class="input-group-append">
                              <span class="input-group-text">.00</span>
                            </div>
                          </div>
                        </div>
                        <div className="form-group required col-md-6">
                          <label className="control-label">Precio Publico</label>
                          <div className="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">$</span>
                            </div>
                            <input type="number" className="form-control" placeholder="Precio Publico" name="precio_publico"
                            maxLength="7" value={this.state.precio_publico} onChange={this.handleInputChange} ></input>
                            <div class="input-group-append">
                              <span class="input-group-text">.00</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-row justify-content-md-center">
                        <button type="button" 
                          className="btn btn-danger btn-block mr-2" 
                          style={{width: "150px"}} 
                          onClick={() => this.formularioTour(false, {})}>
                          <i className="fa fa-arrow-circle-left"></i> Volver
                        </button>
                        <button type="button" 
                          className="btn btn-primary btn-block" 
                          style={{width: "150px"}}
                          onClick={() => this.guardarTour()} >
                          <i className="fa fa-save"></i> Guardar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <p></p>
        }
      </LoadingOverlay>
    );
  }
}

export default ListaTours;