import React from 'react';
import "./tour.css";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';

class Tour extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      nombre: '',
      id_proveedor: '',
      detalles: '',
      ddProveedores: []
    };

    this.proveedoresRef = React.createRef();
    this.handleInputChange = this.handleInputChange.bind(this);

    this.setState({loader: true});
    axios.get('/api/CatalogosController.php/ProveedoresTours')
    .then(result => {
      this.setState({
        loader: false,
        ddProveedores: result.data.data,
        id_proveedor: result.data.data[0].value
      });
      if(this.props.match.params.id !== undefined){
        this.setState({loader: true});
        axios.get('/api/ToursController.php/DetallesTours',{
          params:{
            id:this.props.match.params.id,
          }
        })
        .then(result => {
          this.setState({
            loader: false,
            nombre:result.data.data[0].nombre,
            id_proveedor: result.data.data[0].id_proveedor_tour,
            detalles: result.data.data[0].detalles
          });

          this.proveedoresRef.current.value = parseInt(result.data.data[0].id_proveedor_tour);
        })
        .catch(console.log);      
      }
    })
    .catch(console.log);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  guardarTour(){
    if(this.state.nombre == ''){
      infoMessage(2, 'Error de captura','Capture el nombre para poder continuar.');
      return false;
    }
    if(this.state.capacidad == ''){
      infoMessage(2, 'Error de captura','Capture la cantidad para poder continuar.');
      return false;
    }
    if(this.state.precio_proveedor == ''){
      infoMessage(2, 'Error de captura','Capture el precio proveedor para poder continuar.');
      return false;
    }
    if(this.state.precio_publico == ''){
      infoMessage(2, 'Error de captura','Capture el precio publico para poder continuar.');
      return false;
    }

    this.setState({loader: true});
    if(this.props.match.params.id !== undefined){
      axios.put('/api/ToursController.php/Tours',{
        id:this.props.match.params.id,
        nombre:this.state.nombre,
        id_proveedor: this.state.id_proveedor,
        detalles: this.state.detalles,
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }else{
      axios.post('/api/ToursController.php/Tours',
      {
        nombre:this.state.nombre,
        id_proveedor: this.state.id_proveedor,
        detalles: this.state.detalles,
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div className="form-row">
                      <div className="form-group required col-md-6">
                        <label className="control-label">Nombre Tour</label>
                        <input type="text" className="form-control" placeholder="Nombre" name="nombre" 
                        maxLength="60" value={this.state.nombre} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-6">
                        <label className="control-label">Proveedor</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_proveedor} name="id_proveedor" ref={this.proveedoresRef}>
                          {this.state.ddProveedores.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="inputAddress">Detalles</label>
                      <textarea type="text" className="form-control" placeholder="Detalles" name="detalles"
                        value={this.state.detalles} onChange={this.handleInputChange} ></textarea>
                    </div>
                    <div className="form-row justify-content-md-center">
                      <Link to="/listatours">
                        <button type="button" 
                          className="btn btn-danger btn-block mr-2" 
                          style={{width: "150px"}} >
                          <i className="fa fa-arrow-circle-left"></i> Volver
                        </button>
                      </Link>
                      <button type="button" 
                        className="btn btn-primary btn-block" 
                        style={{width: "150px"}}
                        onClick={() => this.guardarTour()} >
                        <i className="fa fa-save"></i> Guardar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default Tour;