import React from 'react';
import "./reservacionYate.css";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import DatePicker from 'react-date-picker';

class ReservacionYate extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      cliente: '',
      telefono: '',
      totalPersonas: '',
      fecha:new Date(),
      horaReservacion: '',
      horas: '',
      id_yate: '',
      precio: '',
      anticipo: '',
      ganancia: '',
      detalles: '',
      ddYates: [],
      ddYatePrecio: 0,
      ddYateGanacia: 0,
    };

    this.ddYatesRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.setState({loader: true});
    axios.get('/api/CatalogosController.php/Yates')
    .then(result => {
      this.setState({
        loader: false,
        ddYates: result.data.data,
        yateSelected: result.data.data[0].value,
        id_yate: result.data.data[0].value,
        ddYatePrecio: result.data.data[0].precio,
        ddYateGanacia: result.data.data[0].ganancia,
      });

      this.ddYatesRef.current.value = parseInt(result.data.data[0].value);
      this.ddYatesRef.current.dataset.precio = parseInt(result.data.data[0].precio);
      this.ddYatesRef.current.dataset.ganancia = parseInt(result.data.data[0].ganancia);
      
    })
    .catch(console.log);
  }

  handleInputChange(event) {
    if(event.target.name == 'id_yate'){
      this.setState({
        precio:(parseInt(this.state.horas) * parseInt(event.target.options[event.target.selectedIndex].dataset.precio)),
        ganancia:(parseInt(this.state.horas) * parseInt(event.target.options[event.target.selectedIndex].dataset.ganancia)),
        ddYatePrecio: event.target.options[event.target.selectedIndex].dataset.precio,
        ddYateGanacia: event.target.options[event.target.selectedIndex].dataset.ganancia,
      });
    }

    if(event.target.name == 'horas'){
      this.setState({
        precio:(parseInt(event.target.value) * parseInt(this.state.ddYatePrecio)),
        ganancia:(parseInt(event.target.value) * parseInt(this.state.ddYateGanacia)),
      });
    }

    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  handleChangeDate(event) {
    this.setState({fecha: event});
  }

  guardarReservacion(){
    if(this.state.cliente == ''){
      infoMessage(2, 'Error de captura','Capture el cliente para poder continuar.');
      return false;
    }
    if(this.state.telefono == ''){
      infoMessage(2, 'Error de captura','Capture el telefono para poder continuar.');
      return false;
    }
    if(this.state.totalPersonas == ''){
      infoMessage(2, 'Error de captura','Capture la cantidad de personas para poder continuar.');
      return false;
    }
    if(this.state.fecha == ''){
      infoMessage(2, 'Error de captura','Capture la fecha para poder continuar.');
      return false;
    }
    if(this.state.horaReservacion == ''){
      infoMessage(2, 'Error de captura','Capture la hora de reservacion para poder continuar.');
      return false;
    }
    if(this.state.horas == ''){
      infoMessage(2, 'Error de captura','Capture las horas para poder continuar.');
      return false;
    }
    if(this.state.precio == ''){
      infoMessage(2, 'Error de captura','Capture el precio para poder continuar.');
      return false;
    }
    if(this.state.anticipo == ''){
      infoMessage(2, 'Error de captura','Capture el anticipo para poder continuar.');
      return false;
    }
    if(this.state.ganancia == ''){
      infoMessage(2, 'Error de captura','Capture la ganancia para poder continuar.');
      return false;
    }

    this.setState({loader: true});
    axios.post('/api/ReservacionesYatesController.php/ReservacionesYates',
    {
      cliente: this.state.cliente, 
      telefono: this.state.telefono, 
      totalPersonas: this.state.totalPersonas, 
      fecha: this.state.fecha,
      horaReservacion: this.state.horaReservacion, 
      horas: this.state.horas, 
      id_yate: this.state.id_yate, 
      precio: this.state.precio, 
      anticipo: this.state.anticipo, 
      ganancia: this.state.ganancia, 
      detalles: this.state.detalles,
    })
    .then(result => {
      this.setState({loader: false});
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
    })
    .catch(console.log);
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div className="form-row">
                      <div className="form-group required col-md-4">
                        <label className="control-label">Cliente</label>
                        <input type="text" className="form-control" name="cliente" 
                        maxLength="60" value={this.state.cliente} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Telefono</label>
                        <input type="number" className="form-control" name="telefono" 
                        maxLength="10" value={this.state.telefono} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Total De Personas</label>
                        <input type="number" className="form-control" name="totalPersonas" 
                        maxLength="2" value={this.state.totalPersonas} onChange={this.handleInputChange} ></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-4">
                        <label className="control-label">Fecha De Reservacion</label>
                        <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                          value={this.state.fecha}/>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Hora De Reservacion</label>
                        <input type="number" className="form-control" name="horaReservacion" 
                        maxLength="2" value={this.state.horaReservacion} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Horas</label>
                        <input type="number" className="form-control" name="horas" 
                        maxLength="2" value={this.state.horas} onChange={this.handleInputChange} ></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-3">
                        <label className="control-label">Yate</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_yate} name="id_yate" ref={this.ddYatesRef}>
                          {this.state.ddYates.map((result) => {
                            return(
                              <option value={result.value} data-precio={result.precio} 
                                data-ganancia={result.ganancia}>
                                {result.text}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Precio Final</label>
                        <div className="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input type="number" className="form-control" name="precio"
                          maxLength="7" value={this.state.precio} onChange={this.handleInputChange} ></input>
                          <div class="input-group-append">
                            <span class="input-group-text">.00</span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Ganancia Estimada</label>
                        <div className="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input type="number" className="form-control" name="ganancia"
                          maxLength="7" value={this.state.ganancia} onChange={this.handleInputChange} ></input>
                          <div class="input-group-append">
                            <span class="input-group-text">.00</span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Anticipo</label>
                        <div className="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input type="number" className="form-control" name="anticipo"
                          maxLength="7" value={this.state.anticipo} onChange={this.handleInputChange} ></input>
                          <div class="input-group-append">
                            <span class="input-group-text">.00</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="inputAddress">Detalles</label>
                      <textarea type="text" className="form-control" name="detalles"
                        value={this.state.detalles} onChange={this.handleInputChange} ></textarea>
                    </div>
                    <div className="form-row justify-content-md-center">
                      <Link to="/listareservacionesyates">
                        <button type="button" 
                          className="btn btn-dark btn-block mr-2" 
                          style={{width: "150px"}} >
                          <i className="fa fa-arrow-circle-left"></i> Volver
                        </button>
                      </Link>
                      <button type="button" 
                        className="btn btn-primary btn-block" 
                        style={{width: "150px"}}
                        onClick={() => this.guardarReservacion()} >
                        <i className="fa fa-save"></i> Guardar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default ReservacionYate;