import React from 'react';
import "./reservacionTour.css";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import DatePicker from 'react-date-picker';

class ReservacionTour extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      cliente: '',
      telefono: '',
      fecha:new Date(),
      horaReservacion: '',
      id_tour: '',
      precio: 0,
      pickup: '',
      anticipo: 0,
      ganancia: 0,
      detalles: '',
      ddTours: [],
      tourText: '',
      paquetes: [],
    };

    this.ddToursRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.setState({loader: true});
    axios.get('/api/CatalogosController.php/Tours')
    .then(result => {
      this.setState({
        loader: false,
        ddTours: result.data.data,
        id_tour: result.data.data[0].value,
        tourText: result.data.data[0].detalles,
      });

      this.ddToursRef.current.value = parseInt(result.data.data[0].value);
      
    })
    .catch(console.log);

    if(this.props.match.params.id !== undefined){
      this.setState({loader: true});
      axios.get('/api/ReservacionesToursController.php/DetallesTours',{
        params:{
          id:this.props.match.params.id,
        }
      })
      .then(result => {
        this.setState({
          loader: false,
          cliente: result.data.data[0].nombre,
          telefono: result.data.data[0].telefono,
          pickup: result.data.data[0].pickup,
          fecha: new Date(result.data.data[0].fecha_reservacion),
          horaReservacion: result.data.data[0].hora_reservacion,
        });
      })
      .catch(console.log);      
    }
  }

  sumarTotales() {
    let FTotal = 0;
    let FGanancia = 0;
    this.state.paquetes.map((result) => {
      FTotal += result.cantidad * result.precio_venta;
      FGanancia += (result.cantidad * result.precio_venta) - (result.cantidad * result.precio_proveedor);
    });

    this.setState({
      ganancia: FGanancia,
      precio: FTotal
    });
  }

  handleInputChange(event) {
    if(event.target.name == 'precio_venta'){
      this.state.paquetes[event.target.dataset.indice].precio_venta = event.target.value;
      this.state.paquetes[event.target.dataset.indice].total = event.target.value * this.state.paquetes[event.target.dataset.indice].cantidad;

      this.sumarTotales();
    }

    if(event.target.name == 'precio_proveedor'){
      this.state.paquetes[event.target.dataset.indice].precio_proveedor = event.target.value;

      this.sumarTotales();
    }

    if(event.target.name == 'id_tour'){
      this.setState({
        tourText: event.target.options[event.target.selectedIndex].dataset.detalles
      });

      this.setState({loader: true});
      axios.get('/api/CatalogosController.php/PaquetesTours?id='+event.target.value)
      .then(result => {
        this.setState({
          loader: false,
          paquetes: result.data.data,
        });

        this.sumarTotales();
      }).catch(console.log);
    }

    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  handleChangeDate(event) {
    this.setState({fecha: event});
  }

  agregarPaquete(indice, tipo){
    if(tipo == 'suma'){
      this.state.paquetes[indice].cantidad = this.state.paquetes[indice].cantidad + 1;     
    }else{
      this.state.paquetes[indice].cantidad = this.state.paquetes[indice].cantidad - 1; 
    }
    this.state.paquetes[indice].cantidad = Math.max(this.state.paquetes[indice].cantidad, 0);
    this.state.paquetes[indice].total = this.state.paquetes[indice].precio_venta * this.state.paquetes[indice].cantidad;


    this.setState({
      paquetes: this.state.paquetes
    }); 

    this.sumarTotales();
  }

  guardarReservacion(){
    if(this.state.cliente == ''){
      infoMessage(2, 'Error de captura','Capture el cliente para poder continuar.');
      return false;
    }
    if(this.state.telefono == '' || this.state.telefono.toString().length < 10){
      infoMessage(2, 'Error de captura','Capture el telefono correctamente para poder continuar.');
      return false;
    }
    if(this.state.fecha.toISOString().substring(0,10) < new Date().toISOString().substring(0,10)){
      infoMessage(2, 'Error de captura','La fecha no puede ser menor a la actual.');
      return false;
    }
    if(this.state.horaReservacion == ''){
      infoMessage(2, 'Error de captura','Capture la hora para poder continuar.');
      return false;
    }
    if(parseInt(this.state.precio) <= 0){
      infoMessage(2, 'Error de captura','El precio debe ser mayor a 0 para poder continuar.');
      return false;
    }

    this.setState({loader: true});
    if(this.props.match.params.id !== undefined){
      axios.put('/api/ReservacionesToursController.php/ReservacionesTours',
      {
        id:this.props.match.params.id,
        cliente: this.state.cliente, 
        telefono: this.state.telefono, 
        fecha: this.state.fecha.toISOString().substring(0,10),
        horaReservacion: this.state.horaReservacion,  
        precio: this.state.precio, 
        pickup: this.state.pickup, 
        detalles: this.state.detalles, 
        ganancia: this.state.ganancia, 
        anticipo: this.state.anticipo, 
        paquetes: this.state.paquetes
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }else{
      axios.post('/api/ReservacionesToursController.php/ReservacionesTours',
      {
        cliente: this.state.cliente, 
        telefono: this.state.telefono, 
        fecha: this.state.fecha.toISOString().substring(0,10),
        horaReservacion: this.state.horaReservacion,  
        precio: this.state.precio, 
        pickup: this.state.pickup, 
        detalles: this.state.detalles, 
        ganancia: this.state.ganancia, 
        anticipo: this.state.anticipo, 
        paquetes: this.state.paquetes
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-7">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div className="form-row">
                      <div className="form-group required col-md-12">
                        <label className="control-label">Cliente</label>
                        <input type="text" className="form-control" name="cliente" 
                        maxLength="60" value={this.state.cliente} onChange={this.handleInputChange} ></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-5">
                        <label className="control-label">Telefono</label>
                        <input type="number" className="form-control" name="telefono" 
                        maxLength="60" value={this.state.telefono} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-5">
                        <label className="control-label">Fecha</label>
                        <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                          value={this.state.fecha}/>
                      </div>
                      <div className="form-group required col-md-2">
                        <label className="control-label">Hora</label>
                        <input type="text" className="form-control" name="horaReservacion" 
                        maxLength="60" value={this.state.horaReservacion} onChange={this.handleInputChange} ></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-12">
                        <label className="control-label">Pick Up</label>
                        <input type="text" className="form-control" name="pickup" 
                        maxLength="60" value={this.state.pickup} onChange={this.handleInputChange} ></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-4">
                        <label className="control-label">Precio Final</label>
                        <div className="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input type="number" className="form-control" name="precio"
                          maxLength="7" value={this.state.precio} onChange={this.handleInputChange} disabled></input>
                          <div class="input-group-append">
                            <span class="input-group-text">.00</span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Ganancia Estimada</label>
                        <div className="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input type="number" className="form-control" name="ganancia"
                          maxLength="7" value={this.state.ganancia} onChange={this.handleInputChange} disabled></input>
                          <div class="input-group-append">
                            <span class="input-group-text">.00</span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Anticipo</label>
                        <div className="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input type="number" className="form-control" name="anticipo"
                          maxLength="7" value={this.state.anticipo} onChange={this.handleInputChange} ></input>
                          <div class="input-group-append">
                            <span class="input-group-text">.00</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="inputAddress">Detalles</label>
                      <textarea type="text" className="form-control" name="detalles"
                        value={this.state.detalles} onChange={this.handleInputChange} ></textarea>
                    </div>
                    <div className="form-row justify-content-md-center">
                      <Link to="/listareservacionestours">
                        <button type="button" 
                          className="btn btn-dark btn-block mr-2" 
                          style={{width: "150px"}} >
                          <i className="fa fa-arrow-circle-left"></i> Volver
                        </button>
                      </Link>
                      <button type="button" 
                        className="btn btn-block bg-gradient-primary" 
                        style={{width: "150px"}}
                        onClick={() => this.guardarReservacion()} >
                        <i className="fa fa-save"></i> Guardar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-5">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div className="form-row">
                      <div className="form-group required col-md-12">
                        <label className="control-label">Tours</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_tour} name="id_tour" ref={this.ddToursRef}>
                          {this.state.ddTours.map((result) => {
                            return(
                              <option value={result.value} data-detalles={result.detalles}>
                                {result.text}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-12">
                        <label>{this.state.tourText}</label>
                      </div>
                    </div>
                    {this.state.paquetes.map((paqResult, indice) => {
                      return(
                        <div className="card" style={{padding:"10px"}}>
                          <form>
                            <div className="form-row">
                              <div className="form-group col-md-12">
                                <center>
                                  <label className="control-label">{paqResult.nombre}</label>
                                </center>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group required col-md-4">
                                <center>
                                  <button type="button" class="btn btn-block bg-gradient-success btn-sm btnSum"
                                    onClick={() => this.agregarPaquete(indice, 'resta')}>-</button>
                                </center>
                              </div>
                              <div className="form-group col-md-4">
                                <center>
                                  <label className="control-label">{paqResult.cantidad}</label>
                                </center>
                              </div>
                              <div className="form-group required col-md-4">
                                <center>
                                  <button type="button" class="btn btn-block bg-gradient-success btn-sm btnSum"
                                    onClick={() => this.agregarPaquete(indice, 'suma')}>+</button>
                                </center>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-3">
                                <label>Precio Real: </label>
                              </div>
                              <div className="col-md-3">
                                <input type="number" className="form-control" name="precio_proveedor" 
                                  data-indice={indice}
                                  maxLength="60" value={paqResult.precio_proveedor} onChange={this.handleInputChange } ></input>
                              </div>
                              <div className="col-md-6">
                                <label>Precio Sugerido: {paqResult.precio_publico}</label>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-3">
                                <label>Precio:</label>
                              </div>
                              <div className="col-md-3">
                                <input type="number" className="form-control" name="precio_venta" 
                                  data-indice={indice}
                                  maxLength="60" value={paqResult.precio_venta} onChange={this.handleInputChange } ></input>
                              </div>
                              <div className="col-md-6">
                                <center>
                                  <label>Total: {paqResult.total}</label>
                                </center>
                              </div>
                            </div>
                          </form>
                        </div>
                      );
                    })}
                    <div className="form-row justify-content-md-center">
                      <Link to="/listareservacionesyates">
                        <button type="button" 
                          className="btn btn-dark btn-block mr-2" 
                          style={{width: "150px"}} >
                          <i className="fa fa-arrow-circle-left"></i> Volver
                        </button>
                      </Link>
                      <button type="button" 
                        className="btn btn-block bg-gradient-primary" 
                        style={{width: "150px"}}
                        onClick={() => this.guardarReservacion()} >
                        <i className="fa fa-save"></i> Guardar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default ReservacionTour;