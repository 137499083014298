import React from 'react';
import "./catalogoCasas.css";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';

class CatalogoCasas extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      dataTable: [],
      filtro: '',
      loader: true,
      total:0
    };
    this.loadData();
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  loadData = () => {
    this.setState({loader: true});
    axios.get('/api/CasasController.php/CatalogoCasas')
    .then(result => {

      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          dataTable: result.data.data
        });

       this.checkAll(); 
      },1000);
    })
    .catch(console.log);
  }

  checkAll(){
    var cbarray = document.getElementsByName('cb');
    for(var i = 0; i < cbarray.length; i++){
      cbarray[i].checked = true;
    }  
  }

  handleInputChange(event) {
    if(event.target.name == 'tarifa_venta_normal'){
      this.state.dataTable[event.target.dataset.indice].tarifa_venta_normal = parseInt(event.target.value);
      console.log(this.state.dataTable[event.target.dataset.indice].tarifa_venta_normal);
      console.log(parseInt(event.target.value));
      console.log(this.state.dataTable);

    }
    if(event.target.name == 'tarifa_venta_especial'){
      this.state.dataTable[event.target.dataset.indice].tarifa_venta_especial = parseInt(event.target.value);
    }


    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  generarCatalogo = (tipo) => {
    window.open('/api/CasasController.php/GenerarCatalogo?tipo='+tipo+'&data='+encodeURIComponent(JSON.stringify(this.state.dataTable))).focus();
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="container-fluid min-vh-100"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-center">
                    <h3>Catalogo De Casas</h3>
                  </div>
                  <div className="card-header d-flex justify-content-start">
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-info btn-block btn-sm" 
                        style={{width: "40px"}} 
                        onClick={() => this.loadData()}>
                        <i className="fa fa-sync"></i>
                      </button>
                    </div>
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-success btn-block btn-sm" 
                        style={{width: "150px"}} 
                        onClick={() => this.generarCatalogo('normal')}>
                        <i className="fa fa-file-pdf"></i> Tarifa Normal
                      </button>
                    </div>
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-success btn-block btn-sm" 
                        style={{width: "150px"}} 
                        onClick={() => this.generarCatalogo('alta')}>
                        <i className="fa fa-file-pdf"></i> Tarifa Alta
                      </button>
                    </div>
                  </div>
                  <div className="card" style={{padding:"10px"}}>
                    <form>
                      <div className="form-row">
                        <div className="col-md-1"></div>
                        <div className="col-md-3">
                          <p className="text-center">Propiedad</p>
                        </div>
                        <div className="col-md-2">
                          <p className="text-center">Compra Normal</p>
                        </div>
                        <div className="col-md-2">
                          <p className="text-center">Venta Normal</p>
                        </div>
                        <div className="col-md-2">
                          <p className="text-center">Compra Alta</p>
                        </div>
                        <div className="col-md-2">
                          <p className="text-center">Venta Alta</p>
                        </div>
                      </div>
                      {this.state.dataTable.map((result, indice) => {
                        return(
                          <div className="form-row">
                            <div className="col-md-1 d-flex justify-content-center">
                              <input type="checkbox" name="cb" onChange={(e) => {
                                result.checky = e.target.checked;
                              }}></input>
                            </div>
                            <div className="col-md-3">
                              <p className="text-center">{result.nombre}</p>
                            </div>
                            <div className="col-md-2">
                              <p className="text-center">{result.tarifa_compra_normal}</p>
                            </div>
                            <div className="col-md-2">
                                <input type="number" className="form-control" name="tarifa_venta_normal" 
                                  data-indice={indice}
                                  maxLength="5" value={result.tarifa_venta_normal} onChange={this.handleInputChange}></input>
                            </div>
                            <div className="col-md-2">
                              <p className="text-center">{result.tarifa_compra_especial}</p>
                            </div>
                            <div className="col-md-2">
                                <input type="number" className="form-control" name="tarifa_venta_especial" 
                                  data-indice={indice}
                                  maxLength="5" value={result.tarifa_venta_especial} onChange={this.handleInputChange}></input>
                            </div>
                          </div>
                        );
                      })}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default CatalogoCasas;