import React from 'react';
import "./pagosTours.css";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';

class PagosTours extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      id_tipo_pago: 0,
      id_forma_pago: 0,
      cantidad: 0,
      detalles: '',
      ddTiposDePago: [],
      ddFormasDePago: []
    };

    this.ddTiposDePagoRef = React.createRef();
    this.ddFormasDePagoRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);

    this.setState({loader: true});
    axios.get('/api/CatalogosController.php/TiposDePago')
    .then(result => {
      this.setState({
        ddTiposDePago: result.data.data,
        id_tipo_pago: result.data.data[0].value,
      });
      this.ddTiposDePagoRef.current.value = parseInt(result.data.data[0].value);
      axios.get('/api/CatalogosController.php/FormasDePago')
      .then(result => {
        this.setState({
          loader: false,
          ddFormasDePago: result.data.data,
          id_forma_pago: result.data.data[0].value,
        });
        this.ddFormasDePagoRef.current.value = parseInt(result.data.data[0].value);
      })
      .catch(console.log);
    })
    .catch(console.log);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  guardarPago(){
    if(this.state.cantidad <= 0){
      infoMessage(2, 'Error de captura','Capture una cantidad mayor a 0 para continuar.');
      return false;
    }

    this.setState({loader: true});
    axios.post('/api/PagosController.php/PagosTours',
    {
      id_user:localStorage.getItem('NAid'),
      id_tipo_pago: this.state.id_tipo_pago,
      id_forma_pago: this.state.id_forma_pago,
      cantidad: this.state.cantidad,
      detalles: this.state.detalles
    })
    .then(result => {
      this.setState({loader: false});
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
    })
    .catch(console.log);
  }

  regresarPagina() {
    window.history.back()
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div className="form-row">
                      <div className="form-group required col-md-4">
                        <label className="control-label">Tipo De Pago</label>
                        <select className="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_tipo_pago} name="id_tipo_pago" ref={this.ddTiposDePagoRef}>
                          {this.state.ddTiposDePago.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Forma De Pago</label>
                        <select className="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_forma_pago} name="id_forma_pago" ref={this.ddFormasDePagoRef}>
                          {this.state.ddFormasDePago.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Cantidad</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                          </div>
                          <input type="number" className="form-control" name="cantidad"
                          maxLength="7" value={this.state.cantidad} onChange={this.handleInputChange} ></input>
                          <div className="input-group-append">
                            <span className="input-group-text">.00</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="inputAddress">Detalles</label>
                      <textarea type="text" className="form-control" name="detalles"
                        value={this.state.detalles} onChange={this.handleInputChange} ></textarea>
                    </div>
                    <div className="form-row justify-content-md-center">
                      <button type="button" 
                        className="btn btn-danger btn-block mr-2" 
                        style={{width: "150px"}} 
                        onClick={() => this.regresarPagina()}>
                        <i className="fa fa-arrow-circle-left"></i> Volver
                      </button>
                      <button type="button" 
                        className="btn btn-primary btn-block" 
                        style={{width: "150px"}}
                        onClick={() => this.guardarPago()} >
                        <i className="fa fa-save"></i> Guardar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default PagosTours;