import React from 'react';
import "./finanzasYates.css";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import { Bar } from 'react-chartjs-2';

class FinanzasYates extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      dataTable: [],
      loader: false,
      options : {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
      data : {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
        datasets: [
          {
            label: '# of Red Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: 'rgb(255, 99, 132)',
            stack: 'Stack 0',
          },
          {
            label: '# of Blue Votes',
            data: [2, 3, 20, 5, 1, 4],
            backgroundColor: 'rgb(54, 162, 235)',
            stack: 'Stack 2',
          },
          {
            label: '# of Green Votes',
            data: [3, 10, 13, 15, 22, 30],
            backgroundColor: 'rgb(75, 192, 192)',
            stack: 'Stack 1',
          },
        ],
      }
    };

    this.loadGraph();
    this.loadTableData();
  }

  loadGraph = () => {
    this.setState({loader: true});
    axios.get('/api/GastosGeneralesController.php/GastosGeneralesYates',
    {
      params:{
        fechaInicial:'2021-01-01',//this.state.filtroFechas[0].toISOString().split('T')[0],
        fechaFinal:'2021-12-12'//this.state.filtroFechas[1].toISOString().split('T')[0],
      }
    })
    .then(result => {
      console.log(result.data.data);
      console.log(this.state.data);
      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          data: result.data.data
        });
      },1000);
    })
    .catch(console.log);
  }

  loadTableData = () => {
    this.setState({loader: true});
    axios.get('/api/GastosGeneralesController.php/GastosGeneralesYatesDetalles',
    {
      params:{
        q:''
      }
    })
    .then(result => {
      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          dataTable: result.data.data
        });
      },1000);
    })
    .catch(console.log);
  }  

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="container-fluid min-vh-100"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <div className="table-wrapper-scroll-y my-custom-scrollbar">
                  <table className="table table-bordered table-striped mb-0">
                    <thead>
                      <tr className="Table-Details">
                        <th>Tipo</th>
                        <th>Cantidad</th>
                        <th>Fecha</th>
                        <th>Descripcion</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dataTable.map((result) => {
                        return(
                          <tr>
                            <td><span className={result.tipo_view}>{result.tipo}</span></td>
                            <td>{result.cantidad}</td>
                            <td>{result.fecha_registro}</td>
                            <td>{result.descripcion}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-6">
                <div className="card">
                  <Bar id="bar" data={this.state.data} options={this.state.options} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default FinanzasYates;