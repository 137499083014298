import React from 'react';
import "./llamadaPolizasAutomovil.css";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import DatePicker from 'react-date-picker';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class LlamadaPolizasAutomovil extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      poliza: '',
      detalles: '',
      cliente: '',
      telefono: '',
      correo: '',
      id_tipo_poliza: '',
      id_seguro_agente: '',
      fecha_emision:'',
      fecha_lista:'',
      dias_restantes:'',
      ddResultadoLlamada: [],
      historialLlamadas: [],
      id_tipo_llamada:'',
      comentarioLlamada:'',
      ddTiposPolizas: [],
      ddSegurosAgentes: [],
    };

    this.tiposPolizasRef = React.createRef();
    this.segurosAgentesRef = React.createRef();
    this.tiposLlamadasRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.setState({loader: true});
    axios.get('/api/CatalogosController.php/TiposLlamadas')
    .then(result => {
      this.setState({
        loader: false,
        ddResultadoLlamada: result.data.data,
        llamadaSelected: result.data.data[0].value,
        id_tipo_llamada: result.data.data[0].value
      });
      this.tiposLlamadasRef.current.value = parseInt(result.data.data[0].value); 
      axios.get('/api/CatalogosController.php/SegurosAgentes')
      .then(result => {
        this.setState({
          loader: false,
          ddSegurosAgentes: result.data.data,
          agenteSelected: result.data.data[0].value,
          id_seguro_agente: result.data.data[0].value
        });
        this.segurosAgentesRef.current.value = parseInt(result.data.data[0].value); 

        axios.get('/api/CatalogosController.php/TiposPolizas')
        .then(result => {
          this.setState({
            loader: false,
            ddTiposPolizas: result.data.data,
            polizaSelected: result.data.data[0].value,
            id_tipo_poliza: result.data.data[0].value
          });
          this.tiposPolizasRef.current.value = parseInt(result.data.data[0].value); 

          if(this.props.match.params.id !== undefined){
            this.setState({loader: true});
            axios.get('/api/PolizasAutomovilController.php/DetallesPolizaAutomovil',{
              params:{
                id:this.props.match.params.id,
              }
            })
            .then(result => {
              this.setState({
                loader: false,
                poliza: result.data.data[0].poliza,
                detalles: result.data.data[0].descripcion,
                cliente: result.data.data[0].cliente,
                telefono: result.data.data[0].telefono,
                correo: result.data.data[0].correo,
                fecha_emision:result.data.data[0].fecha_emision,
                //fecha_lista:result.data.data[0].fecha_lista,
                dias_restantes:result.data.data[0].intervalo
              });

              this.tiposPolizasRef.current.value = parseInt(result.data.data[0].id_tipo_poliza);
              this.segurosAgentesRef.current.value = parseInt(result.data.data[0].id_seguro_agente);
            })
            .catch(console.log);      
          }

        }).catch(console.log);

      }).catch(console.log);
    }).catch(console.log);

    axios.get('/api/PagoPolizasAutomovilController.php/HistorialLlamadas',{
      params:{
        id:this.props.match.params.id,
      }
    }).then(result => {
      this.setState({
        historialLlamadas:result.data.data
      });
    }).catch(console.log);
  }


  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  handleChangeDate(event) {
    this.setState({fecha_lista: event});
  }

  saveModal = (identificador) => {
    if(identificador == 1){
      console.log(this.tiposPolizasRef.current.value);
      let mes = '';
      switch (this.tiposPolizasRef.current.value) {
        case '1':
          mes = 12;
          break;
        case '2':
          mes = 1;
          break;
        case '3':
          mes = 3;
          break;
        case '4':
          mes = 6;
          break;
      }
      confirmAlert({
        message: 'Desea agregar pago?[Se agregarian '+mes+' meses a la poliza].',
        buttons: [
          {
            label: 'Continuar',
            onClick: () => this.agregarPago()
          },
          {
            label: 'Cancelar'
          }
        ],
        overlayClassName: 'backGroundDialog'
      });
    }else if(identificador == 2){
      confirmAlert({
        message: 'El cliente no aparece en la lista hasta la fecha seleccionada¿Desea continuar?.',
        buttons: [
          {
            label: 'Continuar',
            onClick: () => this.cambiarFechaLista()
          },
          {
            label: 'Cancelar'
          }
        ],
        overlayClassName: 'backGroundDialog'
      });
    }else if(identificador == 3){
      confirmAlert({
        message: 'Desea agregar la llamada?.',
        buttons: [
          {
            label: 'Continuar',
            onClick: () => this.guardarLlamada()
          },
          {
            label: 'Cancelar'
          }
        ],
        overlayClassName: 'backGroundDialog'
      });
    }
  }

  guardarLlamada(){
    this.setState({loader: true});
    axios.post('/api/PagoPolizasAutomovilController.php/AgregarLlamada',
    {
      id: this.props.match.params.id, 
      comentario: this.state.comentarioLlamada, 
      tipo: this.state.id_tipo_llamada
    })
    .then(result => {
      this.setState({loader: false});
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      setTimeout(() => {
        window.location.reload();
      },1200);
    })
    .catch(console.log);
  }

  agregarPago(){
    this.setState({loader: true});
    axios.post('/api/PagoPolizasAutomovilController.php/AgregarPago',
    {
      id: this.props.match.params.id
    })
    .then(result => {
      this.setState({loader: false});
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      setTimeout(() => {
        window.location.reload();
      },1200);
    })
    .catch(console.log);
  }

  cambiarFechaLista(){
    this.setState({loader: true});
    axios.post('/api/PagoPolizasAutomovilController.php/CambiarFechaLista',
    {
      id: this.props.match.params.id,
      fecha: this.state.fecha_lista
    })
    .then(result => {
      this.setState({loader: false});
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      setTimeout(() => {
        window.location.reload();
      },1200);
    })
    .catch(console.log);
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
                <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title"><b>Ultimos Pagos</b></h3>
                  </div>
                  <div class="card-body">
                    <ul class="pagination pagination-month justify-content-center">
                      <li class="page-item"><a class="page-link">«</a></li>
                      <li class="page-item">
                          <a class="page-link">
                              <p class="page-month">Jan</p>
                              <p class="page-year">2021</p>
                          </a>
                      </li>
                      <li class="page-item">
                          <a class="page-link">
                              <p class="page-month">Feb</p>
                              <p class="page-year">2021</p>
                          </a>
                      </li>
                      <li class="page-item">
                          <a class="page-link">
                              <p class="page-month">Mar</p>
                              <p class="page-year">2021</p>
                          </a>
                      </li>
                      <li class="page-item">
                          <a class="page-link">
                              <p class="page-month">Apr</p>
                              <p class="page-year">2021</p>
                          </a>
                      </li>
                      <li class="page-item">
                          <a class="page-link">
                              <p class="page-month">May</p>
                              <p class="page-year">2021</p>
                          </a>
                      </li>
                      <li class="page-item">
                          <a class="page-link">
                              <p class="page-month">Jun</p>
                              <p class="page-year">2021</p>
                          </a>
                      </li>
                      <li class="page-item">
                          <a class="page-link">
                              <p class="page-month">Jul</p>
                              <p class="page-year">2021</p>
                          </a>
                      </li>
                      <li class="page-item">
                          <a class="page-link">
                              <p class="page-month">Aug</p>
                              <p class="page-year">2021</p>
                          </a>
                      </li>
                      <li class="page-item">
                          <a class="page-link">
                              <p class="page-month">Sep</p>
                              <p class="page-year">2021</p>
                          </a>
                      </li>
                      <li class="page-item">
                          <a class="page-link">
                              <p class="page-month">Oct</p>
                              <p class="page-year">2021</p>
                          </a>
                      </li>
                      <li class="page-item">
                          <a class="page-link">
                              <p class="page-month">Nov</p>
                              <p class="page-year">2021</p>
                          </a>
                      </li>
                      <li class="page-item">
                          <a class="page-link">
                              <p class="page-month">Dec</p>
                              <p class="page-year">2021</p>
                          </a>
                      </li>
                      <li class="page-item"><a class="page-link">»</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-7">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b>INFORMACION DEL CLIENTE</b>
                      </h3>
                      <div className="form-row justify-content-end">
                        <Link to="/listareservacionesyates">
                          <button type="button" 
                            className="btn-sm btn-danger btn-block">
                            <i className="fa fa-arrow-circle-left"></i> Volver
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="control-label">Poliza</label>
                        <input type="text" className="form-control" name="poliza" 
                        maxLength="60" value={this.state.poliza} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="control-label">Cliente</label>
                        <input type="text" className="form-control" name="cliente" 
                        maxLength="60" value={this.state.cliente} onChange={this.handleInputChange} disabled></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="control-label">Telefono</label>
                        <input type="number" className="form-control" name="telefono" 
                        maxLength="10" value={this.state.telefono} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="control-label">Correo</label>
                        <input type="text" className="form-control" name="correo" 
                        maxLength="60" value={this.state.correo} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="control-label">Fecha De Emision</label>
                        <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                          value={this.state.fecha_emision} disabled={true}/>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="control-label">Tipo De Poliza</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_tipo_poliza} name="id_tipo_poliza" ref={this.tiposPolizasRef} disabled>
                          {this.state.ddTiposPolizas.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="control-label">Agente</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_seguro_agente} name="id_seguro_agente" ref={this.segurosAgentesRef} disabled>
                          {this.state.ddSegurosAgentes.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="inputAddress">Detalles</label>
                      <textarea type="text" className="form-control" name="detalles"
                        value={this.state.detalles} onChange={this.handleInputChange} disabled></textarea>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-5">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b>DATOS DE LISTA</b>
                      </h3>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-8">
                        <label className="control-label">Dias Restantes</label>
                        <input type="text" className="form-control" name="dias_restantes" 
                        maxLength="60" value={this.state.dias_restantes} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-4">
                        <button type="button" 
                          className="btn btn-block bg-gradient-success mt-4" 
                          onClick={() => this.saveModal(1)} >
                          <i className="fa fa-plus-circle"></i> Agregar
                        </button>
                      </div>
                    </div>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b></b>
                      </h3>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-8">
                        <label className="control-label">Ocultar De Lista</label>
                        <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                          value={this.state.fecha_lista} />
                      </div>
                      <div className="form-group col-md-4">
                        <button type="button" 
                          className="btn btn-block bg-gradient-success mt-4" 
                          onClick={() => this.saveModal(2)} >
                          <i className="fa fa-calendar-week"></i> Actualizar
                        </button>
                      </div>
                    </div>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b></b>
                      </h3>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-8">
                        <label className="control-label">Resultado</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_tipo_llamada} name="id_tipo_llamada" ref={this.tiposLlamadasRef}>
                          {this.state.ddResultadoLlamada.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-md-4">
                        <button type="button" 
                          className="btn btn-block bg-gradient-success mt-4" 
                          onClick={() => this.saveModal(3)} >
                          <i className="fa fa-phone"></i> Agregar
                        </button>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <input type="text" className="form-control" name="comentarioLlamada" 
                          maxLength="60" value={this.state.comentarioLlamada} onChange={this.handleInputChange}></input>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{padding:"10px"}}>
                  <div class="card-header">
                    <h3 class="card-title">
                      <b>Ultimas 5 Llamadas</b>
                    </h3>
                  </div>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Fecha</th>
                        <th scope="col">Resultado</th>
                        <th scope="col">Comentario</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.historialLlamadas.map((result) => {
                        return(
                          <tr>
                            <td>{result.fecha_registro}</td>
                            <td>{result.nombre}</td>
                            <td>{result.descripcion}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default LlamadaPolizasAutomovil;